import Vue from 'vue'
import VueRouter from 'vue-router'
import Error from '../components/Error.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name:'languageeducation',
    component: ()=>import('../views/languageEducation'),
    meta:{
      layout: 'LanguageEducation'
    }
  },
  {
    path: '/:catchAll(.*)*',
    component:Error,
    name:"Error",
    meta:{
      layout: 'Error'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
